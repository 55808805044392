
<div class="form-group title_container">
    <div class="form-group title_container" v-if="fieldData">
        <!-- Header -->
        <h5 class="modal-h5" for="Nombre" v-if="useTitle">
            <span style="color: #F80048;">{{fieldData.required ? '*' : ''}}</span>{{fieldData.title}}
        </h5>
        <!-- Field -->
        <Field
            :fieldData="fieldData"
            :readonly="readonly"
            v-model="localValue"/>

        <!-- Error -->

        <span class="has_error py-1" v-show="errors.has(fieldData.slug)">{{fieldData.validation_text}}</span>
    </div>
</div>
