<template>
  <div>
    <VuePhoneNumberInput
      v-model="phoneInput"
      ref="phoneNumberInput"
      :default-country-code="preferredCountries[0]"
      :preferred-countries="preferredCountries"
      :border-radius="0"
      :name="fieldData.slug"
      :data-vv-as="fieldData.slug"
      :translations="translations"
      :disabled="readonly"
      v-validate="required"/>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  inject: ['$validator'],
  props: {
    value: [Object],
    readonly: {
      type: Boolean,
      default: false
    },
    fieldData: {
      type: Object,
      required: true
    }
  },
  inject: ['$validator'],
  components: { VuePhoneNumberInput },
  computed: {
    required() {
      return (this.fieldData && this.fieldData.required ? 'required|' : '') + 'phone'
    }
  },
  data: () => ({
    type: 'mobile',
    countryCode: '+52',
    country: '',
    number: '',
    maskedNumber: '',
    preferredCountries: ['US', 'MX'],
    phoneInput: null,
    translations: null
  }),
  mounted() {
    if (this.$locale == 'es') {
      this.preferredCountries = ['MX', 'US'];
      this.translations = {
        countrySelectorLabel: 'País',
        countrySelectorError: 'País',
        phoneNumberLabel: 'Número de teléfono',
        example: 'Ejemplo:'
      }
    }

    setTimeout(() => {
      let phoneNumberInput = this.$refs.phoneNumberInput;
      if (this.value){
        this.maskedNumber = this.value.maskedNumber;
        this.phoneInput = this.value.maskedNumber;
        if (phoneNumberInput){
          phoneNumberInput.setLocale(this.value.country);
        }
      }
    }, 500)
  },
  watch: {
    phoneInput(){
      let phoneNumberInput = this.$refs.phoneNumberInput;
      if (phoneNumberInput){
        let value = {
          countryCode: '+' + phoneNumberInput.results.countryCallingCode,
          country: phoneNumberInput.results.countryCode,
          number: phoneNumberInput.results.nationalNumber,
          fullNumber: phoneNumberInput.results.e164,
          maskedNumber: phoneNumberInput.results.formatInternational
        }
        this.$emit('input', value);
      }
    }
  }
}
</script>