
<div>
    <template v-if="fieldData.data_type_enum == 21"> <!--  Checkbox boolean  -->
        <input type="checkbox" 
            class="me-2"
            :id="`enum_${fieldData.data_type_enum}`"
            v-model="localValue" 
            :placeholder="placeholder"
            :data-vv-as="fieldData.slug"
            :name="fieldData.slug" 
            v-validate="required"/>
        <label class="boolean_field me-3" :for="`enum_${fieldData.data_type_enum}`" v-html="placeholder"></label>
    </template>
</div>
