<template>
    <div>
        <v-select
            class="country-input"
            :id="`enum_${fieldData.data_type_enum}`"
            :data-vv-as="fieldData.slug"
            :name="fieldData.slug" 
            v-validate="required"
            :class="{'input_readonly': readonly}"
            v-model="localValue" 
            :placeholder="fieldData.placeholder"
            :readonly="readonly"
            :clearable="false"
            :filterable="false"
            :options="sortedCountries"
            @search="onInputSearch">
        
            <template slot="no-options">
                {{ noOptions }}
            </template>

        </v-select>
    </div>
</template>
<script>
import Countries from './countries_with_accents.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    inject: ['$validator'],
    props:{
        fieldData: {
            type: Object
        },
        readonly: {
            type: Boolean
        },
        value: {
            type: String
        }
    },
    components: { vSelect },
    mixins: [Countries],
    data: () => ({
        searchString: null
    }),
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        required() {
            return (this.fieldData.required ? 'required' : '');
        },
        noOptions() {
            return this.$locale == 'es' ? 'Lo sentimos, no hay opciones de coincidencia.' : 'Sorry, no matching options.';
        },
        computedCountries() {
            return this.countries && this.countries[this.$locale] ? this.countries[this.$locale].sort() : [];
        },
        sortedCountries() {
            return this.searchString ? 
                this.computedCountries.filter(x => x.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.searchString ))
            : this.computedCountries;
        }
    },
    methods: {
        onInputSearch(search) {
            this.searchString = search.replaceAll(' ', '') != '' ? search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : null
        }
    }
}
</script>
<style lang="scss">
.country-input div span {
    color: black!important;
}
.country-input .vs__dropdown-toggle{
    background-color: var(--white);
    padding: 0px 0px;
    font-size: 1em;
    color: var(--black);
    font-weight: 500;
    width: 100%;
    border: none;
}
.country-input .vs__dropdown-toggle::placeholder {
    color: rgb(54, 54, 54);
}

.country-input,
.country-input .vs__dropdown-toggle,
.country-input .vs__dropdown-toggle .vs__selected-options {
    height: 44px;
    margin: 0px 0px !important;
    background-color: var(--white);
    padding: 0px 0px;
    font-size: 1em;
    color: var(--black);
    font-weight: 500;
    width: 100%;
    border: none;
}

.country-input .vs__dropdown-toggle .vs__selected-options .vs__search {
    background-color: transparent !important;
}

.country-input .vs__dropdown-toggle .vs__selected-options .vs__selected {
    padding: 5px 20px;
}
</style>