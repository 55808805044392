<template>
    <div>
        <template v-if="fieldData.data_type_enum == 21"> <!--  Checkbox boolean  -->
            <input type="checkbox" 
                class="me-2"
                :id="`enum_${fieldData.data_type_enum}`"
                v-model="localValue" 
                :placeholder="placeholder"
                :data-vv-as="fieldData.slug"
                :name="fieldData.slug" 
                v-validate="required"/>
            <label class="boolean_field me-3" :for="`enum_${fieldData.data_type_enum}`" v-html="placeholder"></label>
        </template>
    </div>
</template>
<script>
export default {
    inject: ['$validator'],
    props:{
        fieldData: {
            type: Object
        },
        readonly: {
            type: Boolean
        },
        value: {
            type: Boolean
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        required() {
            return this.fieldData && this.fieldData.required ? 'required' : ''
        },
        placeholder() {
            const styles = 'class="terms-link"'
            const defaultValue = this.$locale === 'es' ? 'Acepto los términos y condiciones' : 'I agree with the terms and conditions'

            return (this.fieldData.placeholder || defaultValue).replace('términos y condiciones', ` <a ${styles} href="/es/aviso-de-privacidad"> términos y condiciones</a> `)
                    .replace('terminos y condiciones', ` <a ${styles} href="/es/aviso-de-privacidad"> términos y condiciones</a> `)
                    .replace('terms and conditions', ` <a ${styles} href="/en/notice-of-privacy"> terms and conditions</a> `);
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-like,
.btn-like:hover{
    color: green;
}
.btn-dislike,
.btn-dislike:hover{
    color: red;
}

.boolean_field {
    color: white;
    font-size: 1em;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: none;
    display: initial;
}
</style>