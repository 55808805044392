<template>
    <div class="form-group title_container">
        <div class="form-group title_container" v-if="fieldData">
            <!-- Header -->
            <h5 class="modal-h5" for="Nombre" v-if="useTitle">
                <span style="color: #F80048;">{{fieldData.required ? '*' : ''}}</span>{{fieldData.title}}
            </h5>
            <!-- Field -->
            <Field
                :fieldData="fieldData"
                :readonly="readonly"
                v-model="localValue"/>

            <!-- Error -->

            <span class="has_error py-1" v-show="errors.has(fieldData.slug)">{{fieldData.validation_text}}</span>
        </div>
    </div>
</template>
<script>
import Field from './Field.vue';

export default {
    inject: ['$validator'],
    props:{
        fieldData: {
            type: Object
        },
        value: {
            type: [String, Object, Boolean, Number]
        },
        readonly: Boolean
    },
    components: {
        Field
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        useTitle() {
            return this.fieldData && this.fieldData.data_type_enum != 21
        }
    }
}
</script>
<style>
.modal-h5{
    font-size: .85em;
    font-weight: 700;
}
</style>