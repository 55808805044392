
<div class="static-form" v-if="!showCatalogs">
    <form class="vue_contact_form">
        <!-- header -->
        <Header 
            :title="data.config_json && data.config_json.form_title ? data.config_json.form_title : data.title"
            :description="data.description"/>
        <!-- layout -->
        <div v-for="(field , index) in formFields"
            class="vue_title_container"
            :class="field.help">
            <FieldLayoutManager
                v-if="!(disabledFields[field.slug] && readonly)"
                :fieldData="field"
                :readonly="disabledFields[field.slug] && readonly"
                v-model="formFilled[field.slug]"/>
        </div>

        <!-- Footer -->
        <Footer :data="data" :formFilled="formFilled" :buttonText="buttonText" :locale="locale" @finished="finished"/>
    </form>
</div>
<div class="row" v-else-if="catalogs.length > 0">
    <div class="text-center catalog-title" v-if="textHelpers && textHelpers.catalogTitle" v-html="textHelpers.catalogTitle"></div>
    
    <div class="d-flex flex-wrap align-items-center justify-content-center">
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 d-flex align-items-center flex-column justify-content-center mb-3" v-for="(catalog, index) in catalogs" :key="index">
            <img v-if="catalog.terminal_image" class="catalog-img" :src="catalog.terminal_image.public_path"/>
            <div class="text-center my-2" v-if="textHelpers && textHelpers.catalogText" v-html="getCatalogText(catalog.title)"></div>
            
            <div class="d-flex justify-content-center">
                <button v-if="buttonText" 
                    class="btn px-4 btn-secondary d-flex justify-content-center"
                    @click.prevent="sendEmail(catalog.message_template, index, catalog.title)"
                    :disabled="status.download[index]">

                    
                    <div class="pe-3" v-html="buttonText"></div> 
                    <div v-if="status.download[index]" class="text-center">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
                </button>
            </div>
        </div>
    </div>
</div>
