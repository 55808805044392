<template>
    <div class="col-12 form_header">
        <h5 class="mt-3 mb-4 text-uppercase dark3">{{title}}</h5>
        <p class="muted mb-3">{{description}}</p>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        description: String
    }
}
</script>