<template>
    <div class="static-form" v-if="!showCatalogs">
        <form class="vue_contact_form">
            <!-- header -->
            <Header 
                :title="data.config_json && data.config_json.form_title ? data.config_json.form_title : data.title"
                :description="data.description"/>
            <!-- layout -->
            <div v-for="(field , index) in formFields"
                class="vue_title_container"
                :class="field.help">
                <FieldLayoutManager
                    v-if="!(disabledFields[field.slug] && readonly)"
                    :fieldData="field"
                    :readonly="disabledFields[field.slug] && readonly"
                    v-model="formFilled[field.slug]"/>
            </div>

            <!-- Footer -->
            <Footer :data="data" :formFilled="formFilled" :buttonText="buttonText" :locale="locale" @finished="finished"/>
        </form>
    </div>
    <div class="row" v-else-if="catalogs.length > 0">
        <div class="text-center catalog-title" v-if="textHelpers && textHelpers.catalogTitle" v-html="textHelpers.catalogTitle"></div>
        
        <div class="d-flex flex-wrap align-items-center justify-content-center">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3 d-flex align-items-center flex-column justify-content-center mb-3" v-for="(catalog, index) in catalogs" :key="index">
                <img v-if="catalog.terminal_image" class="catalog-img" :src="catalog.terminal_image.public_path"/>
                <div class="text-center my-2" v-if="textHelpers && textHelpers.catalogText" v-html="getCatalogText(catalog.title)"></div>
                
                <div class="d-flex justify-content-center">
                    <button v-if="buttonText" 
                        class="btn px-4 btn-secondary d-flex justify-content-center"
                        @click.prevent="sendEmail(catalog.message_template, index, catalog.title)"
                        :disabled="status.download[index]">

                        
                        <div class="pe-3" v-html="buttonText"></div> 
                        <div v-if="status.download[index]" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FieldLayoutManager from './FieldLayoutManager.vue';
import Header from './Header&Footer/Header.vue';
import Footer from './Header&Footer/Footer.vue';
export default {
    inject: ['$validator'],
    props:{
        data:  Object,
        catalogs: Array,
        textHelpers: Object,
        locale: String,
        readonly: {
            type: Boolean,
            default: true
        }
    },
    components: {
        FieldLayoutManager, Footer, Header
    },
    mounted() {
        if (this.data.hideAfterSubmission && this.$contact) {
            this.finished(true);
            return;
        }

        this.$nextTick(() => {
            // validators will be paused until the user hits 'OK' the first time
            this.$validator.pause();

            this.formFields.forEach(field => {
                const value = this.$contact && this.$contact[field.slug] ? this.$contact[field.slug] : null;
                if (value) this.disabledFields[field.slug] = true;
                if (this.$contact) this.$set(this.formFilled, field.slug, value)
            });

            if (this.$contact) {
                const index = this.formFields.findIndex(x => x.slug === 'phone');
                if (index != -1) {
                    this.formFields[index].help = "col-12 col-sm-12 col-md-12"
                }
            }
        });
    },
    data: () => ({
        formFilled: {},
        showCatalogs: false,
        status: {
            download: {}
        },
        disabledFields: {},
        emailsSent: []
    }),
    computed: {
        formFields() {
            return this.data && this.data.config_json ? this.data.config_json.fields : []
        },
        buttonText() {
            return this.data && this.data.config_json && this.data.config_json.button_text ? this.data.config_json.button_text : '';
        }
    },
    methods: {
        getCatalogText(text) {
            return Boolean(this.textHelpers?.catalogText) ? this.textHelpers.catalogText.replaceAll("{{catalog}}", text) : '';
        },
        finished(value) {
            if(this.data.hideAfterSubmission) {
                this.showCatalogs = value;
                const img = document.getElementById('form-download-image');
                if (img) img.style.display = "none";

                const container = document.getElementById('absolute-form');
                if (container) container.style.justifyContent = "center";
            } else {
                this.$emit('finished', this.formFilled);
                this.resetData();
            }
        },
        resetData() {
            this.$nextTick(() => {
                // validators will be paused until the user hits 'OK' the first time
                this.$validator.pause();
                Object.keys(this.formFilled).forEach(key => {
                    if(!this.disabledFields[key] || key === 'phone') this.$set(this.formFilled, key, null);
                    if (key === 'phone') this.disabledFields.phone = true;
                })
            });
        },
        sendEmail(emails, index, title) {
            if (this.emailsSent.includes(index)) {
                const reclamed = this.$locale == 'es' ? 'Se ha enviado un correo para descargar el catálogo.' : 'An email has been sent to download the catalog.';
                this.$swal('', reclamed, 'warning');
                return;
            }

            const email = emails.find(x => (x.language).toLowerCase() === this.locale.toLowerCase());
            const contactEmail = (this.formFilled ? this.formFilled.email : null) || (this.$contact ? this.$contact.email : null);

            if (email && contactEmail) {
                this.$set(this.status.download, index, true);

                this.$axios.post(`/api/v1/catalog/${email.template}/email`, {
                    data: {
                        email: contactEmail,
                        locale: this.locale,
                    }
                }).then(() => {
                    this.emailsSent.push(index);

                    if (this.data.config_json.confirm_notification) {
                        const notif = this.data.config_json.confirm_notification;
                        const notifTitle = (notif.title || '').replace('{{terminal}}', title);
                        const notifDescription= (notif.description || '').replace('{{terminal}}', title);
                        this.$swal(notifTitle, notifDescription, 'success');
                    }
                }).catch(() => {
                    this.$swal('Oops..','Something was wrong','error');
                }).finally(() => {
                    this.$set(this.status.download, index, false);
                })
            }
        }
    }
}
</script>
<style scoped>
.vue_contact_form{
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
}
.catalog-img {
    width: calc(9vw + 9vh);
    height: calc(12vw + 12vh);
    object-fit: contain;
}
.catalog-title{
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 40px 0;
}
</style>