<template>
    <div>
        <!-- Simple text -->
        <template v-if="fieldData.data_type_enum === 10">
            <input type="text" class="input-type-1 form-control" 
                :id="`enum_${fieldData.data_type_enum}`"
                :data-vv-as="fieldData.slug"
                :name="fieldData.slug" 
                v-validate="required"
                :class="{'input_readonly': readonly}"
                v-model="localValue" 
                :placeholder="fieldData.placeholder"
                :readonly="readonly"/>
        </template>
        <!-- text area -->
        <template v-if="fieldData.data_type_enum === 11">
            <textarea type="text" class="form-control" 
                :id="`enum_${fieldData.data_type_enum}`"
                :data-vv-as="fieldData.slug"
                :name="fieldData.slug" 
                v-validate="required"
                :class="{'input_readonly': readonly}"
                v-model="localValue" 
                :placeholder="fieldData.placeholder"
                rows="8"
                :readonly="readonly"/>
        </template>
        <!-- Simple formatted text -->
        <template v-if="fieldData.data_type_enum === 12">
            <div v-if="!readonly"> 
                <vue-editor v-model="localValue"
                    :id="`enum_${fieldData.data_type_enum}`"
                    :editorToolbar="defaultCustomToolbar" 
                    :data-vv-as="fieldData.slug"
                    :name="fieldData.slug" 
                    v-validate="required">
                </vue-editor>
            </div>

            <div v-else v-html="localValue" :class="{'input_readonly': readonly}"></div>
        </template>
        
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
    inject: ['$validator'],
    props:{
        fieldData: {
            type: Object
        },
        readonly: {
            type: Boolean
        },
        value: {
            type: String
        }
    },
    components: {
        VueEditor //, Editor
    },
    data: () => ({
        defaultCustomToolbar: [
            [{ 'font': [] }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'color': [] }, { 'background': [] }],
        ],
        content: null
    }),
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        required() {
            return (this.fieldData.required ? 'required|' : '') + (this.fieldData.slug === 'email' ? 'email' : '');
        }
    }
}
</script>
<style scoped>
textarea {
  resize: none;
}
</style>