<template>
    <div class="w-100 mb-4">
        <div class=" form_footer w-100">
            <div class="d-flex justify-content-end">
                <button type="submit" :disabled="status.submit" @click.prevent="submit()" class="btn btn-primary d-flex align-items-center">
                    <div class="d-flex align-items-center flex-nowrap m-0">
                        <div class="pe-3 pointer" v-html="buttonText"></div>
                        <div v-if="status.submit" class="spinner-border text-light spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['$validator'],
    props: {
        formFilled: Object,
        buttonText: String,
        data:       Object,
        locale: String
    },
    data: () => ({
        status: {
            submit: false
        }
    }),
    methods: {
        submit() {
            this.$validator.resume();
            this.$validator.validate().then(value => {
                if (value) {
                    this.status.submit = true;
                    const login = (typeof this.data.loginAfterSubmission == 'undefined') ? `?locale=${this.locale}` : `?login=true&locale=${this.locale}`
                    this.$axios.post(`/api/v1/form/${this.data.slug}/submit${login}`, {
                        data: this.formFilled
                    }).then(response => {
                        this.manageCaliforniaACtion(response.data.data);
                    }).catch(() => {
                        this.$swal('Oops..','Something was wrong','error');
                    }).finally(() => {
                        this.status.submit = false;
                    })
                }
            });
        },
        manageCaliforniaACtion(response) {
            let californiaAction = this.data.config_json.action_on_submit ? this.data.config_json.action_on_submit : false;
            const config = this.data && this.data.config_json ? this.data.config_json : {};

            if (californiaAction == 'message' && config.notification) {
                this.$swal(config.notification.title,config.notification.description,'success');
            } else if (californiaAction == 'redirect' && Boolean(config.redirect_url_on_submit.replaceAll(' ', ''))) {
                window.location.href = config.redirect_url_on_submit;
            }

            this.$emit('finished', true);
        }
    }
}
</script>
<style scoped>
.error{
    padding: 4px 25px 5px;
    font-size: 14px;
}
</style>