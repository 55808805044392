
<div class="w-100 mb-4">
    <div class=" form_footer w-100">
        <div class="d-flex justify-content-end">
            <button type="submit" :disabled="status.submit" @click.prevent="submit()" class="btn btn-primary d-flex align-items-center">
                <div class="d-flex align-items-center flex-nowrap m-0">
                    <div class="pe-3 pointer" v-html="buttonText"></div>
                    <div v-if="status.submit" class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
                </div>
            </button>
        </div>
    </div>
</div>
