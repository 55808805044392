<template>
    <div>
        <!-- container for contact without submission -->
        <StaticForm
            :data="data"
            :catalogs="catalogs"
            :textHelpers="textHelpers"
            :locale="locale"
            :readonly="readonly"
            ref="staticForm"
            @finished="finished"/>
    </div>
</template>
<script>
//Forms types
import StaticForm from './components/StaticForm.vue';

export default {
    $_veeValidate: {
      validator: 'new' // give me my own validator scope.
    },
    props:{
        data:  Object,
        catalogs: Array,
        textHelpers: Object,
        locale: String,
        readonly: {
            type: Boolean,
            default: true
        }
    },
    components: {
        StaticForm
    },
    methods: {
        finished(data) {
            this.$emit('finished', data);
        }
    }
}
</script>