
<div class="form-group title_container me-0 me-sm-2 mb-3">

    <template v-if="fieldData.slug == 'country'">
        <CountryField
            v-model="localValue"
            :fieldData="fieldData"
            :readonly="readonly"/>
    </template>

    <template v-else-if="fieldData.data_type_enum >= 10 && fieldData.data_type_enum < 15">
        <TextField
            v-model="localValue"
            :fieldData="fieldData"
            :readonly="readonly"/>
    </template>

    <template v-else-if="fieldData.data_type_enum == 15">
        <PhoneField
            v-model="localValue"
            :fieldData="fieldData"
            :readonly="readonly"/>
    </template>

    <template v-else-if="fieldData.data_type_enum >= 21 && fieldData.data_type_enum <= 28">
        <BooleanField
            v-model="localValue"
            :fieldData="fieldData"
            :readonly="readonly"/>
    </template>
</div>
