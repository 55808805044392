<template>
    <div class="form-group title_container me-0 me-sm-2 mb-3">

        <template v-if="fieldData.slug == 'country'">
            <CountryField
                v-model="localValue"
                :fieldData="fieldData"
                :readonly="readonly"/>
        </template>

        <template v-else-if="fieldData.data_type_enum >= 10 && fieldData.data_type_enum < 15">
            <TextField
                v-model="localValue"
                :fieldData="fieldData"
                :readonly="readonly"/>
        </template>

        <template v-else-if="fieldData.data_type_enum == 15">
            <PhoneField
                v-model="localValue"
                :fieldData="fieldData"
                :readonly="readonly"/>
        </template>

        <template v-else-if="fieldData.data_type_enum >= 21 && fieldData.data_type_enum <= 28">
            <BooleanField
                v-model="localValue"
                :fieldData="fieldData"
                :readonly="readonly"/>
        </template>
    </div>
</template>
<script>
import TextField from './Fields/TextField.vue'
import BooleanField from './Fields/BooleanField.vue'
import PhoneField from './Fields/PhoneField.vue'
import CountryField from './Fields/CountryField.vue'

export default {
    props:{
        fieldData: {
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Object, Boolean, Number]
        }
    },
    components: {
        TextField, BooleanField, PhoneField, CountryField
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<style lang="scss">
.register-form {
    .vue-phone-number-input {
        border-color: rgb(216, 216, 216)!important;
        background-color: transparent!important;
        border: 1px solid;
        height: 26px !important;
    }
    .input-tel__input,
    .country-selector__input {
        height: 26px !important;
        max-height: 26px !important;
        min-height: 26px !important;
        border-radius: 0;
        border: 1px solid white !important;
    }
    .country-selector {
        .country-selector__country-flag {
            top: 14px !important;
            left: 40px !important;
        }
        .country-selector__label {
            font-size: 10px !important;
        }
        .country-selector__toggle {
            top: calc(40% - 11px) !important;
        }
    }
    .input-tel {
        left: 1px;
        .input-tel__label {
            font-size: 9px;
            top: 0px;
        }
    }
}
</style>