
<div>
    <v-select
        class="country-input"
        :id="`enum_${fieldData.data_type_enum}`"
        :data-vv-as="fieldData.slug"
        :name="fieldData.slug" 
        v-validate="required"
        :class="{'input_readonly': readonly}"
        v-model="localValue" 
        :placeholder="fieldData.placeholder"
        :readonly="readonly"
        :clearable="false"
        :filterable="false"
        :options="sortedCountries"
        @search="onInputSearch">
    
        <template slot="no-options">
            {{ noOptions }}
        </template>

    </v-select>
</div>
