
<div>
    <!-- container for contact without submission -->
    <StaticForm
        :data="data"
        :catalogs="catalogs"
        :textHelpers="textHelpers"
        :locale="locale"
        :readonly="readonly"
        ref="staticForm"
        @finished="finished"/>
</div>
