
<div>
  <VuePhoneNumberInput
    v-model="phoneInput"
    ref="phoneNumberInput"
    :default-country-code="preferredCountries[0]"
    :preferred-countries="preferredCountries"
    :border-radius="0"
    :name="fieldData.slug"
    :data-vv-as="fieldData.slug"
    :translations="translations"
    :disabled="readonly"
    v-validate="required"/>
</div>
