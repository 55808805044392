export default {
    data: () => ({
        countries: {
            en: [
                "Afghanistan",
                "Åland Islands",
                "Albania",
                "Algeria",
                "American Samoa",
                "Andorra",
                "Angola",
                "Anguilla",
                "Antarctica",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Aruba",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bermuda",
                "Bhutan",
                "Bolivia, Plurinational State of",
                "Bonaire, Sint Eustatius and Saba",
                "Bosnia and Herzegovina",
                "Botswana",
                "Bouvet Island",
                "Brazil",
                "British Indian Ocean Territory",
                "Brunei Darussalam",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde",
                "Cayman Islands",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Christmas Island",
                "Cocos (Keeling) Islands",
                "Colombia",
                "Comoros",
                "Congo",
                "Congo, the Democratic Republic of the",
                "Cook Islands",
                "Costa Rica",
                "Côte d'Ivoire",
                "Croatia",
                "Cuba",
                "Curaçao",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Falkland Islands (Malvinas)",
                "Faroe Islands",
                "Fiji",
                "Finland",
                "France",
                "French Guiana",
                "French Polynesia",
                "French Southern Territories",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Gibraltar",
                "Greece",
                "Greenland",
                "Grenada",
                "Guadeloupe",
                "Guam",
                "Guatemala",
                "Guernsey",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Heard Island and McDonald Islands",
                "Holy See (Vatican City State)",
                "Honduras",
                "Hong Kong",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran, Islamic Republic of",
                "Iraq",
                "Ireland",
                "Isle of Man",
                "Israel",
                "Italy",
                "Jamaica",
                "Japan",
                "Jersey",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Korea, Democratic People's Republic of",
                "Korea, Republic of",
                "Kuwait",
                "Kyrgyzstan",
                "Lao People's Democratic Republic",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macao",
                "Macedonia, the Former Yugoslav Republic of",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Martinique",
                "Mauritania",
                "Mauritius",
                "Mayotte",
                "Mexico",
                "Micronesia, Federated States of",
                "Moldova, Republic of",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Montserrat",
                "Morocco",
                "Mozambique",
                "Myanmar",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Caledonia",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Niue",
                "Norfolk Island",
                "Northern Mariana Islands",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine, State of",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Pitcairn",
                "Poland",
                "Portugal",
                "Puerto Rico",
                "Qatar",
                "Réunion",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "Saint Barthélemy",
                "Saint Helena, Ascension and Tristan da Cunha",
                "Saint Kitts and Nevis",
                "Saint Lucia",
                "Saint Martin (French part)",
                "Saint Pierre and Miquelon",
                "Saint Vincent and the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Sint Maarten (Dutch part)",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Georgia and the South Sandwich Islands",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Svalbard and Jan Mayen",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Syrian Arab Republic",
                "Taiwan, Province of China",
                "Tajikistan",
                "Tanzania, United Republic of",
                "Thailand",
                "Timor-Leste",
                "Togo",
                "Tokelau",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Turks and Caicos Islands",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States",
                "United States Minor Outlying Islands",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Venezuela, Bolivarian Republic of",
                "Viet Nam",
                "Virgin Islands, British",
                "Virgin Islands, U.S.",
                "Wallis and Futuna",
                "Western Sahara",
                "Yemen",
                "Zambia",
                "Zimbabwe"
            ],
            es: [
                "Afganistán",
                "Islas Aland",
                "Albanés",
                "Argelia",
                "Samoa Americana",
                "Andorra",
                "Angoleño",
                "Anguila",
                "Antártida",
                "Antigua y Barbuda",
                "Argentina",
                "Armenia",
                "Aruba",
                "Australia",
                "Austria",
                "azerbaiyano",
                "Bahamas",
                "Bahréin",
                "Bangladés",
                "Barbados",
                "Bielorrusia",
                "Belga",
                "Belice",
                "Benine",
                "Islas Bermudas",
                "butanés",
                "Bolivia, Estado Plurinacional de",
                "Bonaire, San Eustaquio y Saba",
                "Bosnia y Herzegovina",
                "Botsuana",
                "Isla Bouvet",
                "Brasil",
                "Territorio Británico del Océano Índico",
                "Brunei Darussalam",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Camboya",
                "cameron",
                "Canadá",
                "Cabo Verde",
                "Islas Caimán",
                "República Centroafricana",
                "Chad",
                "Chile",
                "Porcelana",
                "Isla de Navidad",
                "Islas Cocos (Keeling)",
                "Colombia",
                "comoras",
                "Congo",
                "Congo, la República Democrática del",
                "Islas Cook",
                "Costa Rica",
                "Costa de Marfil",
                "Croacia",
                "Cuba",
                "Curazao",
                "Chipre",
                "República Checa",
                "Dinamarca",
                "Yibutiense",
                "Dominicano",
                "República Dominicana",
                "Ecuador",
                "Egipto",
                "El Salvador",
                "Guinea Ecuatorial",
                "eritreo",
                "Estonia",
                "Etiopía",
                "Islas Malvinas (Falkland Islands)",
                "Islas Faroe",
                "Fiyi",
                "Finlandia",
                "Francia",
                "Guayana Francesa",
                "Polinesia francés",
                "Territorios Franceses del Sur",
                "Gabón",
                "Gambia",
                "Georgia",
                "Alemania",
                "Ghana",
                "Gibraltar",
                "Grecia",
                "Groenlandia",
                "Granada",
                "Guadalupe",
                "Guam",
                "Guatemala",
                "Guernesey",
                "Guinea",
                "Guinea-Bisáu",
                "Guayana",
                "Haití",
                "Islas Heard y McDonald",
                "Santa Sede (Estado de la Ciudad del Vaticano)",
                "Honduras",
                "Hong Kong",
                "Hungría",
                "Islandia",
                "India",
                "Indonesia",
                "Irán (República Islámica de",
                "Irak",
                "Irlanda",
                "Isla del hombre",
                "Israel",
                "Italia",
                "Jamaica",
                "Japón",
                "Suéter",
                "Jordán",
                "Kazajstán",
                "keniata",
                "kiribati",
                "República de Corea, Popular Democrática de",
                "Corea, república de",
                "Kuwait",
                "Kirguistán",
                "República Democrática Popular Lao",
                "Letón",
                "Líbano",
                "Lesoto",
                "Liberia",
                "Libia",
                "liechtenstein",
                "Lituania",
                "Luxemburgo",
                "Macao",
                "Macedonia, la ex República Yugoslava de",
                "Madagascar",
                "Malaui",
                "Malasia",
                "maldivo",
                "mal",
                "Malta",
                "Islas Marshall",
                "Martinica",
                "Mauritania",
                "Mauricio",
                "mayotte",
                "México",
                "Micronesia, Estados Federados de",
                "Moldavia, República de",
                "Mónaco",
                "Mongolia",
                "Montenegro",
                "Monserrat",
                "Marruecos",
                "Mozambique",
                "Birmania",
                "Namibia",
                "Naurio",
                "Nepal",
                "Países Bajos",
                "Nueva Caledonia",
                "Nueva Zelanda",
                "Nicaragua",
                "Níger",
                "Nigeria",
                "Niue",
                "Isla Norfolk",
                "Islas Marianas del Norte",
                "noruego",
                "Omán",
                "Pakistán",
                "palau",
                "Palestina, Estado de",
                "Panamá",
                "Papúa Nueva Guinea",
                "Paraguayo",
                "Perú",
                "filipinas",
                "pitcairn",
                "Polaco",
                "Portugal",
                "Puerto Rico",
                "Katar",
                "Reunión",
                "Rumania",
                "Federación Rusa",
                "Ruanda",
                "San Bartolomé",
                "Santa Elena, Ascensión y Tristán da Cunha",
                "San Cristóbal y Nieves",
                "Santa Lucía",
                "San Martín (parte francesa)",
                "San Pedro y Miquelón",
                "San Vicente y las Granadinas",
                "Samoa",
                "San Marino",
                "Santo Tomé y Príncipe",
                "Arabia Saudita",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leona",
                "Singapur",
                "Sint Maarten (parte holandesa)",
                "Eslovaquia",
                "Eslovenia",
                "Islas Salomón",
                "Somalia",
                "Sudáfrica",
                "Georgia del sur y las islas Sandwich del sur",
                "Sudán del Sur",
                "España",
                "Sri Lanka",
                "Sudán",
                "Surinam",
                "Svalbard y Jan Mayen",
                "Suazilandia",
                "Suecia",
                "Suiza",
                "República Árabe Siria",
                "Taiwan, provincia de China",
                "Tayikistán",
                "Tanzania, República Unida de",
                "Tailandia",
                "Timor Oriental",
                "Para llevar",
                "Tokelau",
                "Tonga",
                "Trinidad y Tobago",
                "Túnez",
                "Pavo",
                "Turkmenistán",
                "Islas Turcas y Caicos",
                "Tuvalu",
                "Uganda",
                "Ucrania",
                "Emiratos Árabes Unidos",
                "Reino Unido",
                "Estados Unidos",
                "Islas menores alejadas de los Estados Unidos",
                "Uruguay",
                "Uzbekistán",
                "Vanuatu",
                "Venezuela, República Bolivariana de",
                "Vietnam",
                "Islas Vírgenes Británicas",
                "Islas Vírgenes, EE. UU.",
                "Wallis y Futuna",
                "Sahara Occidental",
                "Yemen",
                "Zambia",
                "Zimbabue"
            ]
        }
    })
}